body {
  margin: 0;
  height: 100vh;
}

@font-face {
  font-family: 'Alenia';
  src: local("Alenia"),
    url(/static/media/Alenia.b23a392f.ttf) format("truetype");
  font-weight: normal;
}

.w-md-editor-text-pre>code,
.w-md-editor-text-input {
  font-size: 21px !important;
  line-height: 24px !important;
}

.wmde-markdown {
  background-color: transparent !important;
}
